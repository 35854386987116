import * as React from 'react';
import { Link } from 'gatsby';
import { MainLayout } from 'components/MainLayout';
import { Footer } from 'components/Footer';

const catalogLinkCn =
  'flex items-center h-12 font-medium text-orange-200 bg-orange-100 rounded-md uppercase tracking-wider px-14 mt-5 transition-opacity duration-300 ease-in-out hover:opacity-70';

const NotFoundPage = () => (
  <MainLayout>
    <div className="flex justify-center items-center flex-col flex-grow my-6">
      <p className="text-orange-50 opacity-10 font-medium leading-none" style={{ fontSize: '160px' }}>
        404
      </p>
      <p className="font-medium text-2xl mt-4">Страница не найдена</p>
      <Link to="/catalog" className={catalogLinkCn}>
        В Каталог
      </Link>
    </div>
    <Footer />
  </MainLayout>
);

export default NotFoundPage;
